import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Pagination from "../components/Pagination";
import Title from "../components/Title";
import {getIconFromExtension} from "../Utils/functions";
import CustomBreadcrumb from "../components/CustomBreadcrumb";

const PublicationList = ({pageContext, data, location}) => {
    const [currentYear, setCurrentYear] = useState(null)

    const {
        breadcrumb: {crumbs},
        currentPage,
        numPages,
        basePath
    } = pageContext

    const compteRenduCm = data.compteRenduCm.nodes

    const compteRenduCmByYear = [];

    compteRenduCm.forEach((node) => {
        if (compteRenduCmByYear[node.year] === undefined) {
            compteRenduCmByYear[node.year] = {
                name: node.year,
                nodes: []
            };
        }

        compteRenduCmByYear[node.year].nodes.push(node);
    });

    useEffect(() => {
        let date = new Date();
        setCurrentYear(date.getFullYear());
    })

    return (
        <Layout>
            <Metas title="Comptes-rendus des réunions du conseil municipal"/>
            <section className="section page-breadcrumb is-small has-background-light">
                <div className="container">
                    <CustomBreadcrumb crumbs={crumbs}/>
                </div>
            </section>
            <section className="section page-content">
                <div className="container is-max-desktop">
                    <Title title="Comptes-rendus" subtitle="des réunions du conseil municipal" className="has-text-centered"/>
                    {compteRenduCmByYear.reverse().map((group, index) => (
                        <div key={index} className={`panel group ${group.name === currentYear ? `is-primary` : ``}`}>
                            <div className="panel-heading">Comptes-rendus {group.name}</div>
                            {group.nodes.map((node, index) => (
                                <a key={index} className="panel-block" href={node.relationships.file.localFile.publicURL} title={`${node.relationships.file.localFile.name} (${node.relationships.file.localFile.prettySize})`} download>
                                    <span className="panel-icon">
                                        <i className={`fas fa-${getIconFromExtension(node.relationships.file.localFile.extension)}`} aria-hidden="true"></i>
                                    </span>
                                    <span className="panel-title">
                                        {node.title}
                                    </span>
                                    <small className="panel-date" style={{marginLeft: "auto"}}>
                                        {node.date}
                                    </small>
                                </a>
                            ))}
                        </div>
                    ))}

                    <Pagination
                        path={location.pathname}
                        currentPage={currentPage}
                        numPages={numPages}
                        basePath={basePath}
                    />
                </div>
            </section>
        </Layout>
    )
}

export default PublicationList

export const query = graphql`
    query compteRenduCmQuery($skip: Int!, $limit: Int!) {
        compteRenduCm: allNodeCompteRenduCm(
          sort: {fields: field_date2, order: DESC},
          filter: {status: {eq: true}},
          limit: $limit,
          skip: $skip
        ) {
            nodes {
                id
                title
                year: field_date2(formatString: "YYYY")
                date: field_date2(formatString: "D MMMM", locale: "fr")
                relationships {
                    file: field_fichier {
                        localFile {
                            name
                            extension
                            prettySize
                            publicURL            
                        }
                    }
                }
            }
        }
    }`
